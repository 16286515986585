.create-user {
  width: 100%;
  font-size: 0.875rem !important;
  &__header {
    color: #fff;
    display: flex;
    align-items: center;
    margin-top: 1px;
    height: 2.4286rem;
    background: linear-gradient(to bottom,#0e70b9 0,#005693 100%);
    font-size: 0.9rem;
    padding-left: 0.8rem;
    opacity: 0;
  }
  &__wrapper {
    width: 93%;
    min-width: 20rem;
    box-sizing: border-box;
    margin: 0 auto;
  }
  &__title {
    margin-bottom: 0.625rem;
    font-weight: bold;
  }
  &__item {
    margin-bottom: 1.125rem;
  }
  &__button {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.7143rem;
    width: 70%;
    min-width: 15rem;
    background: #079c20;
    color: #fff;
    position: relative;
    margin: 0 auto;
  }
  &__user-icon-wrapper {
    position: absolute;
    right: 0.5rem;
    top: calc(50% - 0.85rem);
    background: rgba(0, 0, 0, 0.3);
    height: 1.7rem;
    border-radius: 100%;
    width: 1.7rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__user-icon {
    &-svg {
      height: 1.2rem;
    }
  }
}