.users-list {
  &__header {
    // color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1px;
    height: 2.4286rem;
    // background: linear-gradient(to bottom,#0e70b9 0,#005693 100%);
    font-size: 0.9rem;
    padding-left: 0.8rem;
  }
  &__filter-block {
    // padding: 0.7rem;
    width: 86%;
    min-width: 18.75rem;
    margin: 0 auto 3.125rem;
    position: relative;
  }
  &__search-icon {
    position: absolute;
    left: 1rem;
    top: calc(50% - 0.5rem);
    &-svg {
      width: 1rem;
    }
  }

  &__subheader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 92%;
    min-width: 20rem;
    margin: 0 auto 1.25rem auto;
  }

  &__subheader-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 44%;
    min-width: 9.625rem;
    height: 2.5rem;
    box-sizing: border-box;
    border: 1px solid #DDDDDD;
    padding: 0 1rem;
  }

  &__subheader-text {
    max-width: 4.375rem;
    white-space: normal;
    font-size: 0.875rem;
    text-transform: capitalize;
    &_bold {
      font-weight: bold;
    }
  }

  &__bold-text {
    font-weight: bold;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 86.25%;
    min-width: 18.75rem;
    height: 2.5rem;
    box-sizing: border-box;
    padding: 0 1rem 0 1.5rem;
    border: 1px solid #ddd;
    background: #fff;
    font-size: 0.875rem;
    margin: 0 auto 0.5rem auto;
    // &-nick {
    //   color: #00890d;

    //   &_banned {
    //     color: #e4051b;
    //   }
    // }
    &-balance {
      margin-right: 0.875rem;
    }
    // &:nth-child(2n) {
    //   background: #f8f8f8;
    // }
    &-right {
      display: flex;
      align-items: center;
    }
  }

  &__arrow-box {
    // width: 1.5714rem;
    // height: 1.5714rem;
    // border-radius: 100%;
    // background: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    &-svg {
      width: 1rem;
      height: 1rem;
    }
  }

}