.footer {
  width: 97%;
  min-width: 20.75rem;
  display: flex;
  flex-direction: column;
  background: #fff;
  margin: auto auto 4.875rem auto;
  &__item {
    width: 91%;
    min-width: 18.875rem;
    height: 2.75rem;
    display: flex;
    align-items: center;
    // justify-content: space-between;
    box-sizing: border-box;
    border: 1px solid #DDDDDD;
    padding: 0 1rem;
    color: #333;
    margin: 0 auto 0.25rem auto;
    cursor: pointer;
  }
  &__image {
    width: 1.2rem;
    margin-right: 1rem;
    // * {
    //   fill: #333;
    // }
  }
  &__title {
    background: linear-gradient(to bottom,#0e70b9 0,#005693 100%);
    width: 100%;
    height: 2.5rem;
    display: flex;
    align-items: center;
    color: #fff;
    padding-left: 0.7rem;
    box-sizing: border-box;
    font-weight: 400;
    margin-bottom: 0.5rem;
  }
}