.input {
  width: 100%;
  min-height: 2.2em;
  text-align: left;
  box-sizing: border-box;
  outline: 0;
  padding: 0 1.25rem;
  border: 1px solid #ddd;
  font-size: 0.875rem;
  &::placeholder {
    color: #333333;
  }
}
