.subheader {
  background: linear-gradient(to bottom,#0e70b9 0,#005693 100%);
  width: 100%;
  height: 2.8rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  box-sizing: border-box;
  padding: 0 .7rem;
  margin-bottom: 1.25rem;
  &__home {
    width: 1.5rem;
    height: 1.5rem;
  }
  &__image {
    width: 100%;
  }
}