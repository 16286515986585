.user-edit {
  &__info {
    width: 94%;
    min-width: 20rem;
    margin: 0 auto 1.25rem auto;
    &-item {
      margin-bottom: 0.8rem;
    }
    &-title {
      margin-bottom: 0.3rem;
      font-weight: 700;
      text-transform: capitalize;
    }
  }

  &__value {
    width: 100%;
    min-height: 2.2em;
    text-align: left;
    box-sizing: border-box;
    outline: 0;
    padding: 0.5rem;
    border: 1px solid #ddd;
    font-size: 1rem;
  }

  &__buttons {
    padding: 1rem;
  }

  &__payment-buttons {
    display: flex;
    flex-wrap: wrap;
  }

  &__payment-button-value {
    max-width: 20%;
    width: 100px;
    height: 50px;
    margin-right: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: orange;
    cursor: pointer;
    margin-bottom: 1rem;
  }

  &__buttons-bottom {
    display: flex;
    justify-content: space-between;
  }

  &__ban-button,
  &__unban-button {
    cursor: pointer;
    width: 47%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    height: 2.5rem;
    text-shadow: 0px 0px 6px rgba(255, 255, 255, 0.38);
    font-weight: bold;
  }

  &__unban-button {
    background: #079c20;
    color: fff;
  }

  &__ban-button {
    background-color: red;
  }

  &__payment-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 74%;
    min-width: 15rem;
    height: 2.5rem;
    background-color: #079c20;
    color: white;
    font-weight: bold;
    cursor: pointer;
    margin: 0 auto;
    text-shadow: 0px 0px 6px rgba(255, 255, 255, 0.38);
  }

  &__reset-button {
    cursor: pointer;
    background: linear-gradient(180deg, #0d6fb8 0%, #005693 100%);
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    margin-bottom: 1rem;
    text-shadow: 0px 0px 6px rgba(255, 255, 255, 0.38);
    font-weight: bold;
  }

  &__payment-block {
    width: 94%;
    min-width: 20rem;
    margin: 0 auto 2.5rem auto;
    box-sizing: border-box;
  }

  &__payment-button {
    margin-top: 0.8rem;
  }

  &__payment-item {
    margin-bottom: 0.5rem;
  }

  &__payment-title {
    margin-bottom: 0.3rem;
  }
}
