.auth {
  background: #fff;
  &__title {
    background: linear-gradient(to bottom,#0e70b9 0,#005693 100%);
    width: 100%;
    height: 2.5rem;
    display: flex;
    align-items: center;
    color: #fff;
    padding-left: 0.7rem;
    box-sizing: border-box;
    font-weight: 400;
    margin-bottom: 1.875rem;
  }
  &__input-block {
    // padding: 1rem .7rem;
    // border-bottom: 1px solid #bbb;
    width: 93%;
    min-width: 20rem;
    box-sizing: border-box;
    margin: 0 auto 1.875rem auto;
  }
  &__image {
    width: 1.4rem;
    margin-right: 0.5rem;
  }
  &__bottom {
    box-sizing: border-box;
    padding: .7em 1em;
  }
  &__button {
    width: 69%;
    min-width: 15rem;
    max-width: 83rem;
    height: 2.8rem;
    background: #079c20;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    color: #fff;
    cursor: pointer;
    margin: 0 auto;
  }
  &__ok {
    width: 1.8rem;
    position: absolute;
    right: 1rem;
  }
  &__label {
    font-size: 1rem;
    font-weight: 700;
    margin-bottom: 0.625rem;
  }
}